<template>
  <v-container>
    <v-card flat>
      <v-form v-model="valid">
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="organisation.title" :disabled="loading" label="Name"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="organisation.suburb" :disabled="loading" label="Suburb"></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              v-model="organisation.organisation_statuses_id"
              :disabled="loading"
              :items="lists.status"
              :menu-props="{ maxHeight: '400' }"
              label="Status"
              item-text="title"
              item-value="id"
              clearable
            ></v-select>
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              v-model="organisation.organisation_types_id"
              :disabled="loading"
              :items="lists.types"
              :menu-props="{ maxHeight: '400' }"
              label="Type"
              item-text="title"
              item-value="id"
              clearable
            ></v-select>
          </v-col>
          <v-col cols="12">
            <v-select
              v-model="organisation.branch_id"
              :disabled="loading"
              :items="lists.branches"
              :menu-props="{ maxHeight: '400' }"
              label="Branch"
              item-text="title"
              item-value="id"
              clearable
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="text-right">
            <v-btn @click="searchAction" :disabled="loading" class="primary button--search">Search</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-container>
</template>
<style scoped>
.col-md-6,
.col {
  padding-top: 0;
  padding-bottom: 0;
}
.button--search {
  margin-left: 0;
  margin-bottom: 20px;
}
</style>
<script>
export default {
  name: 'OrganisationSearchForm',
  data: () => ({
    valid: false,
    loading: false,
    organisation: {
      active: true
    }
  }),
  props: ['meta'],
  computed: {
    /*
     * We retrive the meta data for each requests. This allows us to update the lists
     * if they have changed in the application.
     */
    lists: function () {
      if (this.meta && this.meta.lists) {
        return this.meta.lists;
      }
      return {
        status: [],
        types: [],
        branches: []
      };
    }
  },
  methods: {
    searchAction: function () {
      this.$emit('clicked-search-button', this.organisation);
    }
  }
};
</script>
